import WebriQForm from "@webriq/gatsby-webriq-form"
import React, { useEffect, useRef, useState } from "react"
import ModalImg from "../../images/new-hero-Background.png"

const SystemIntegConsulModal = ({ onMobile }) => {
  const modalContainer = useRef(null)

  useEffect(() => {
    let timeout
    if (onMobile) {
      timeout = setTimeout(() => {
        const modalContainerRef = modalContainer.current
        if (modalContainerRef) {
          const finishSurvey = sessionStorage.getItem("SystemInteg")
          if (!finishSurvey) {
            modalContainerRef.style.display = "block"
            sessionStorage.setItem("SystemInteg", true)
          }
        }
      }, 8000)
    } else {
      timeout = setTimeout(() => {
        document.addEventListener("mouseleave", e => {
          const modalContainerRef = modalContainer.current
          if (!e.toElement && !e.relatedTarget && modalContainerRef) {
            const finishSurvey = sessionStorage.getItem("SystemInteg")
            if (!finishSurvey) {
              modalContainerRef.style.display = "block"
              sessionStorage.setItem("SystemInteg", true)
            }
          }
        })
      }, 6000)
    }

    return () => clearTimeout(timeout)
  }, [onMobile])

  const onModalClose = () => {
    const modalContainerRef = modalContainer.current
    modalContainerRef.style.display = "none"
  }

  useEffect(() => {
    window.onunload = function() {
      sessionStorage.removeItem("SystemInteg")
    }
  })

  return (
    <>
      <div
        ref={modalContainer}
        className="exit-intent-popup vh-100 w-100 position-fixed"
        style={{
          display: "none",
          zIndex: 5,
          top: 0,
        }}
      >
        <div
          className="vh-100 w-100"
          style={{
            background: "rgba(0, 0, 0, 0.7)",
            top: 0,
            left: 0,
          }}
        >
          <div className="vh-100 position-relative">
            <div onClick={onModalClose}>
              <div className="container">
                <div className="row vh-100">
                  <div className="col-lg-8 align-self-center m-auto px-0">
                    <div
                      style={{
                        backgroundColor: "#fff",
                        padding: "10px",
                        borderRadius: "5px",
                      }}
                    >
                      <div
                        className="rounded"
                        style={{
                          padding: "30px 60px 0 60px",
                          background: `linear-gradient(90deg, rgba(138,73,161,0.8) 0%, rgba(138,73,161,0.8) 100%),url(${ModalImg}) `,
                          backgroundSize: "cover",
                          backgroundPosition: "center center",
                          borderRadius: "5px",
                        }}
                      >
                        <div className="row">
                          <div className="col-lg-11 align-self-center mx-auto text-center p-lg-0">
                            <div
                              id="clost-btn"
                              onClick={onModalClose}
                              className="close-btn exit-intent-close-btn"
                              style={{ top: "-60px", right: "-100px" }}
                            >
                              x
                            </div>
                            <div>
                              <h2 className="font-weight-bold text-white mb-3">
                                Get Procurement Automation Support!
                              </h2>
                              {/* <h5 className="mb-3 text-white">test</h5> */}
                              <WebriQForm
                                id="contact-form"
                                name="Quickbase Form"
                                className="contactForm"
                                data-form-id="10034c81-3cde-4463-bc6f-8df56aa705e0"
                                data-thankyou-url="/thank-you"
                              >
                                <div className="row">
                                  <div className="col-lg-12 col-md-12 col-sm-12">
                                    <input
                                      type="text"
                                      name="full-name"
                                      size={40}
                                      className="form-control"
                                      placeholder="Full Name"
                                      required
                                    />
                                  </div>

                                  <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                                    <input
                                      type="email"
                                      name="your-email"
                                      size={40}
                                      className="form-control"
                                      placeholder="Email"
                                      required
                                    />
                                  </div>
                                  <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                                    <textarea
                                      name="message"
                                      rows={5}
                                      cols={0}
                                      className="form-control"
                                      placeholder="Where do you need support?"
                                    />
                                  </div>
                                  <div className="col-lg-12">
                                    <div className="row mt-3">
                                      <div className="form-group col-lg-12 text-center">
                                        <div className="input-filled">
                                          <button
                                            type="submit"
                                            className="btn btn-primary btn-green w-100"
                                          >
                                            Share Your Procurement Challenges
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group col-12 d-flex justify-content-center captcha-wrap">
                                      <div className="webriq-recaptcha" />
                                    </div>
                                  </div>
                                </div>
                              </WebriQForm>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SystemIntegConsulModal
